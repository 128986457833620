import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"; // Import Col for responsive layout
import Image from "react-bootstrap/Image";
import DolunayGuvenlikImage2 from "../assets/images/DolunayGuvenlikImage2.webp";
import "./components.css";
export default function CorporateComp({
  mainTitle,
  description,
  middleTitle,
  middleDescription = [],
}) {
  return (
    <div className="d-flex justify-content-center align-items-center my-3">
      <Container className="p-5 ContainerDesign">
        {mainTitle && <h3 className="mb-3">{mainTitle}</h3>}
        {description && <div className="mt-2">{description}</div>}
        {middleTitle && <h5 className="my-4">{middleTitle}</h5>}
        {middleDescription && (
          <ul>
            {Array.isArray(middleDescription) &&
              middleDescription.map((item, index) => (
                <li key={index}>
                  <a
                    key={item.id}
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.info}
                  </a>
                </li>
              ))}
          </ul>
        )}
        <Row className="d-flex justify-content-center mt-5">
          <Col xs={12} md={6} lg={4}>
            <Image
              src={DolunayGuvenlikImage2}
              alt="DolunayGuvenlikImage2"
              thumbnail
              className="w-100"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
