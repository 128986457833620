import React, { useState } from "react";
import DolunayGuvenlikBuildingImage1 from "../assets/images/DolunayGuvenlikBuildingImage1.webp";
import DolunayGuvenlikBuildingImage2 from "../assets/images/DolunayGuvenlikBuildingImage2.webp";
import DolunayGuvenlikBuildingImage3 from "../assets/images/DolunayGuvenlikBuildingImage3.webp";
import DolunayGuvenlikBuildingImage4 from "../assets/images/DolunayGuvenlikBuildingImage4.webp";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
export default function CommunicationBuildingImageLayer() {
  const images = [
    DolunayGuvenlikBuildingImage1,
    DolunayGuvenlikBuildingImage2,
    DolunayGuvenlikBuildingImage3,
    DolunayGuvenlikBuildingImage4,
  ];

  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = (selectedIndex) => {
    setIndex(selectedIndex);
    setShow(true);
  };
  const modalStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  };

  const modalContentStyle = {
    backgroundColor: "transparent",
    border: "none",
  };

  const modalBodyStyle = {
    padding: 0,
  };
  return (
    <div>
      <Row className="mb-3">
        {images.map((image, idx) => (
          <Col key={idx} xs={12} md={3}>
            <Image
              src={image}
              rounded
              fluid
              onClick={() => handleShow(idx)}
              style={{ cursor: "pointer" }}
              className="mb-2"
            />
          </Col>
        ))}
      </Row>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        style={modalStyle} // Apply background style here
      >
        <div style={modalContentStyle}>
          <Modal.Body style={modalBodyStyle}>
            <Carousel
              variant="dark"
              activeIndex={index}
              onSelect={(selectedIndex) => setIndex(selectedIndex)}
              fade
            >
              {images.map((image, idx) => (
                <Carousel.Item key={idx}>
                  <Image
                    rounded
                    className="d-block w-100"
                    src={image}
                    alt={`Slide ${idx}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
}
