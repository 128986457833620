import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import { ReactComponent as ExclamationIcon } from "../assets/icons/ExclamationIcon.svg";
import ScoreCalculator from "./ScoreCalculator";
export default function EducationComp({
  mainTitle,
  description = [],
  middleTitle,
  middleDescription = [],
  subTitle,
  subDescription = [],
  formula,
  importantMessage,
}) {
  const alertcontent = {
    display: "flex",
    alignItems: "center",
  };
  const iconcontainer = {
    flexShrink: "0",
    height: "40px",
    width: "40px",
    marginRight: "0.5rem",
  };
  const icon = {
    color: "red",
    height: "100%",
    width: "100%",
  };
  return (
    <Container className="ContainerDesign p-3">
      <h3>{mainTitle}</h3>
      <ul>
        {description.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      {formula && <ScoreCalculator />}
      {importantMessage && (
        <Col>
          {importantMessage.map((msg, idx) => (
            <Alert key={idx} variant="danger" className="mb-3">
              <div className="d-flex align-items-center" style={alertcontent}>
                <div style={iconcontainer}>
                  <ExclamationIcon style={icon} />
                </div>
                <p className="mb-0">
                  <strong>{idx === 0 ? "Silahlı" : "Silahsız"}</strong> {msg}
                </p>
              </div>
            </Alert>
          ))}
        </Col>
      )}
      <h5>{middleTitle}</h5>
      <ul>
        {middleDescription.map((item, index) => (
          <li key={index}>{item.toLocaleUpperCase("tr-TR")}</li>
        ))}
      </ul>
      <h5>{subTitle}</h5>
      <ul>
        {subDescription.map((item, index) => (
          <li key={index}>{item.toLocaleUpperCase("tr-TR")}</li>
        ))}
      </ul>
    </Container>
  );
}
