import React from "react";
import Spinner from "react-bootstrap/Spinner";

export default function SpinnerOverlay() {
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
        background: "rgba(255, 255, 255, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1050,
      }}
    >
      <Spinner animation="grow" role="status" variant="primary" />
    </div>
  );
}
