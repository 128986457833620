import Axios from "axios";
const BASE_URL = "http://94.154.34.245:8080";

// async function getAllUser() {
//   const result = await Axios.get("http://94.154.34.245:8080/user");
//   return result || [];
// }

// async function getById(userID) {
//   const result = await Axios.get(`http://94.154.34.245:8080/user/${userID}`);
//   return result || null;
// }

async function postUser(formData) {
  const data = {
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    phone: formData.phone,
    graduationLevel: formData.graduationLevel,
    birthday: formData.birthday,
    educationType: formData.educationType,
    notes: formData.notes,
  };

  const result = await Axios.post(`${BASE_URL}/user`, data);
  return result || null;
}

// async function updateUser(
//   userID,
//   firstName,
//   lastName,
//   email,
//   phone,
//   graduationLevel,
//   birthday,
//   educationType,
//   notes
// ) {
//   const data = {
//     firstName: firstName || null,
//     lastName: lastName || null,
//     email: email || null,
//     phone: phone || null,
//     graduationLevel: graduationLevel || null,
//     birthday: birthday || null,
//     educationType: educationType || null,
//     notes: notes || null,
//   };
//   const result = Axios.put(`http://94.154.34.245:8080/user/${userID}`, data);
//   return result || [];
// }

// async function deleteUser(userID) {
//   const result = Axios.delete(`http://94.154.34.245:8080/user/${userID}`);
//   return result || null;
// }
async function communation(formData) {
  const data = {
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    phone: formData.phone,
    subjectTitle: formData.subject_title,
    subject: formData.subject,
  };

  const result = await Axios.post(`${BASE_URL}/user/communation`, data);
  return result || null;
}
export const UserAPI = {
  postUser,
  // getAllUser,
  // getById,
  // updateUser,
  // deleteUser,
  communation,
};
