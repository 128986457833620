import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./componentButton.css"; // Import your custom CSS file

export default function ExaminationResultLayer() {
  return (
    <Container style={{ background: "#C0C0C0" }} fluid>
      <Row className="d-flex justify-content-center align-items-center">
        <Col
          md={6}
          className="text-center d-flex justify-content-center align-items-center"
        >
          <Button
            className="custom-button m-3 p-3"
            href="https://onlineislemler.egm.gov.tr/ozelguvenlik/Sayfalar/sinavsonuc.aspx"
            target="_blank"
          >
            <span className="button-text">
              Özel Güvenlik <strong>Temel Eğitim</strong> Sınav Sonucu Öğren
            </span>
          </Button>
        </Col>
        <Col
          md={6}
          className="text-center d-flex justify-content-center align-items-center"
        >
          <Button
            className="custom-button m-3 p-3"
            href="https://onlineislemler.egm.gov.tr/ozelguvenlik/Sayfalar/sinavsonuc.aspx"
            target="_blank"
          >
            <span className="button-text">
              Özel Güvenlik <strong>Yenileme Eğitimi</strong> Sınav Sonucu Öğren
            </span>
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
