import React, { useState, useEffect } from "react";
import DirectoryPointer from "../Components/DirectoryPointer";
import CorporateComp from "../Components/CorporateComp";
import Corporate from "../corporate.json";
import FooterLayer from "../Components/FooterLayer";
export default function AboutPage() {
  const [selectedCorporate, setSelectedCorporate] = useState("");

  useEffect(() => {
    // Find the education data with id 1
    const corporateData = Corporate.find((edu) => edu.id === 1);
    // Set the selected education data to state
    setSelectedCorporate(corporateData);
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <>
      <DirectoryPointer
        url={selectedCorporate?.mainTitle}
        intermediate={"Kurumsal"}
      />
      <CorporateComp
        mainTitle={selectedCorporate.mainTitle}
        description={selectedCorporate.description}
        middleTitle={selectedCorporate.middleTitle}
      />
      <FooterLayer />
    </>
  );
}
