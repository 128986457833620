import React, { useEffect, useState } from "react";
import { ReactComponent as MailIcon } from "../assets/icons/MailIcon.svg";
import { ReactComponent as PinMapIcon } from "../assets/icons/PinMapIcon.svg";
import { ReactComponent as PhoneIcon } from "../assets/icons/PhoneIcon.svg";
import ComInfo from "../comInfo.json";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
export default function CommunicationInfoCol({ width, height, color, title }) {
  const [selectedComInfo, setSelectedComInfo] = useState([]);

  useEffect(() => {
    setSelectedComInfo(ComInfo);
  }, []);

  const getIconSrc = (id) => {
    switch (id) {
      case 1:
        return <PinMapIcon width={width} height={height} color={color} />;
      case 2:
        return <PhoneIcon width={width} height={height} color={color} />;
      case 3:
        return <MailIcon width={width} height={height} color={color} />;
      default:
        return null;
    }
  };
  const handleClick = (item) => {
    switch (item.id) {
      case 1:
        window.open("https://maps.app.goo.gl/pkvYsJPbuVDnu1aW8", "_blank");
        break;
      case 2:
        const phoneNumber = item.information[1];
        window.location.href = `tel:${phoneNumber}`;
        break;
      case 3:
        window.location.href = `mailto:${item.information}`;
        break;
      default:
        break;
    }
  };
  return (
    <Col>
      <Link
        to={"/communication"}
        className={`${title}`}
        style={{ textDecoration: "none" }}
      >
        İletişim
      </Link>
      <hr />
      <ul>
        {selectedComInfo.map((item) => (
          <li
            key={item.id}
            className={"d-flex align-items-center my-3"}
            style={{ cursor: "pointer" }}
            onClick={() => handleClick(item)}
          >
            <div className="me-3">{getIconSrc(item.id)}</div>
            <div>{item.information}</div>
          </li>
        ))}
      </ul>
    </Col>
  );
}
