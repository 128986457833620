import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import "./components.css";

export default function DirectoryPointer({ url, intermediate }) {
  return (
    <div className="font-weight-bold bg-body-tertiary">
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item active>Anasayfa</Breadcrumb.Item>
          {intermediate && (
            <Breadcrumb.Item active>{intermediate}</Breadcrumb.Item>
          )}
          <Breadcrumb.Item active>{url}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </div>
  );
}
