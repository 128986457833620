import React, { useState, useEffect } from "react";
import DirectoryPointer from "../Components/DirectoryPointer";
import EducationComp from "../Components/EducationComp";
import EducationInfo from "../education.json";
import FooterLayer from "../Components/FooterLayer";

export default function EducationFirstPage() {
  const [selectedEducation, setSelectedEducation] = useState("");

  useEffect(() => {
    const educationData = EducationInfo.find((edu) => edu.id === 1);
    setSelectedEducation(educationData);
  }, []);

  return (
    <>
      <DirectoryPointer
        url={selectedEducation?.mainTitle}
        intermediate={"Eğitimler"}
      />
      <EducationComp
        mainTitle={selectedEducation?.mainTitle}
        description={selectedEducation?.description}
        middleTitle={selectedEducation?.middleTitle}
        middleDescription={selectedEducation?.middleDescription}
        subTitle={selectedEducation?.subTitle}
        subDescription={selectedEducation?.subDescription}
        formula={true}
        importantMessage={selectedEducation?.importantMessage}
      />
      <br />
      <FooterLayer />
    </>
  );
}
