import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EducationInfo from "../education.json";
import CorporateComp from "../corporate.json";
import "./components.css";
import CommunicationInfoCol from "./CommunicationInfoCol";
import Dolunayguvenlik from "../assets/images/DolunayGuvenlikLogo.webp";

export default function FooterLayer() {
  const years = "2024";
  const name = "Dolunay Güvenlik";
  const [selectedEducation, setSelectedEducation] = useState([]);
  const [selectedCorporate, setSelectedCorporate] = useState([]);

  useEffect(() => {
    setSelectedCorporate(CorporateComp);
    setSelectedEducation(EducationInfo);
  }, []);

  return (
    <>
      <div className="footerContainer d-flex justify-content-center">
        <Container>
          <Row className="d-flex mt-5 text-white">
            <Col xs={12} md={3} className="text-center">
              <a href="/" className="text-white">
                <img
                  src={Dolunayguvenlik}
                  alt="LOGO"
                  className="img-fluid"
                  style={{ height: "200px" }}
                />
              </a>
            </Col>
            <Col xs={12} md={3}>
              <CommunicationInfoCol
                width={"2rem"}
                height={"2rem"}
                color={"currentColor"}
                title={"h5"}
              />
            </Col>
            <Col xs={12} md={3}>
              <h5>Eğitimlerimiz</h5>
              <hr />
              <ul className="footerListStyle">
                {selectedEducation.map((item) => (
                  <li key={item.id}>
                    <a href={item.url} className="text-white">
                      {item.mainTitle}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
            <Col xs={12} md={3}>
              <h5>Kurumsal</h5>
              <hr />
              <ul className="footerListStyle">
                {selectedCorporate.map((item) => (
                  <li key={item.id}>
                    <a href={item.url} className="text-white">
                      {item.mainTitle}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-light d-flex justify-content-center align-items-center">
        <p className="my-2 mx-5">
          Copyright &copy; {years} {name} ve Eğitim Kurumu TÜM HAKLARI SAKLIDIR.
        </p>
      </div>
    </>
  );
}
