import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import "./components.css";
import DolunayGuvenlikLogo from "../assets/images/DolunayGuvenlikLogo.webp";
import RegisterFormDialog from "./RegisterFormDialog";
import "./componentButton.css";
export default function MyNavbar() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <Navbar expand="lg" className="bg-body-secondary">
      <Container>
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img
            src={DolunayGuvenlikLogo}
            alt="LOGO"
            style={{ height: "50px" }}
            className="me-2"
          />
          <h6>Konya Dolunay Güvenlik ve Eğitim Kurumu</h6>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/">Anasayfa</Nav.Link>
            <NavDropdown title="Eğitimler" id="basic-nav-dropdown">
              <NavDropdown.Item href="/education/1">
                Silahlı Özel Güvenlik Eğitimi
              </NavDropdown.Item>
              <NavDropdown.Item href="/education/2">
                Silahsız Özel Güvenlik Eğitimi
              </NavDropdown.Item>
              <NavDropdown.Item href="/education/3">
                Silahlı(Yenileme) Özel Güvenlik Eğitimi
              </NavDropdown.Item>
              <NavDropdown.Item href="/education/4">
                Silahsız(Yenileme) Özel Güvenlik Eğitimi
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/education/5">
                Silahsızdan Silahlıya Geçiş
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Kurumsal" id="basic-nav-dropdown">
              <NavDropdown.Item href="/corporate/about">
                Hakkımızda
              </NavDropdown.Item>
              <NavDropdown.Item href="/corporate/mission">
                Misyonumuz
              </NavDropdown.Item>
              <NavDropdown.Item href="/corporate/vision">
                Vizyonumuz
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/corporate/legislation">
                Mevzuat
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/communication">İletişim</Nav.Link>
            <Button
              className="custom-button"
              onClick={() => setModalShow(true)}
            >
              Ön Kayıt
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
      {modalShow && (
        <RegisterFormDialog
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
    </Navbar>
  );
}
