import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DirectoryPointer from "../Components/DirectoryPointer";
import FooterLayer from "../Components/FooterLayer";
import GoogleMap from "../Components/GoogleMap";
import CommunicationForm from "../Components/CommunicationForm";
import CommunicationInfoCol from "../Components/CommunicationInfoCol";
import CommunicationBuildingImageLayer from "../Components/CommunicationBuildingImageLayer";

export default function CommunicationPage() {
  return (
    <>
      <DirectoryPointer url={"İletişim"} />
      <div className="d-flex justify-content-center align-items-center">
        <Container>
          <Row className="mt-5">
            <Col xs={12} md={6} className="mb-5">
              <GoogleMap />
            </Col>
            <Col xs={12} md={6}>
              <CommunicationInfoCol
                width={"3rem"}
                height={"3rem"}
                color={"currentColor"}
                title={"h3"}
              />
            </Col>
          </Row>
          <CommunicationBuildingImageLayer />
        </Container>
      </div>
      <div className="bg-light bg-gradient">
        <CommunicationForm />
      </div>
      <FooterLayer />
    </>
  );
}
