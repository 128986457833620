import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import EducationCard from "./EducationCard";
import HomePageEducationInfo from "../homePageEducationInfo.json";

export default function EducationInfoLayer() {
  const [selectedHomePageEducationInfo, setHomePageEducationInfo] = useState(
    []
  );

  useEffect(() => {
    setHomePageEducationInfo(HomePageEducationInfo);
  }, []);

  return (
    <div className="EducationShortInfoContainer d-flex text-white justify-content-center h-50">
      <Container>
        <Row className="justify-content-center mt-5 text-center">
          <h3>Eğitimlerimiz Hakkında</h3>
        </Row>
        <Row className="justify-content-center my-5">
          {selectedHomePageEducationInfo.map((education) => (
            <EducationCard
              key={education.id}
              id={education.id}
              title={education.title}
              description={education.description}
            />
          ))}
        </Row>
      </Container>
    </div>
  );
}
