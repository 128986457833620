import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import "./components.css";
import DolunayGuvenlikImage1 from "../assets/images/DolunayGuvenlikImage1.webp";
import DolunayGuvenlikLogo from "../assets/images/DolunayGuvenlikLogo.webp";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DolunayGuvenlikImage3 from "../assets/images/DolunayGuvenlikImage3.webp";
import "./componentButton.css";
export default function MyCarousels() {
  return (
    <Carousel>
      <Carousel.Item interval={1500}>
        <Container fluid className="p-0">
          <Row noGutters>
            <Col>
              <Image
                src={DolunayGuvenlikImage1}
                className="image-fixed img-fluid"
                alt="Dolunay Guvenlik"
              />
            </Col>
          </Row>
        </Container>
      </Carousel.Item>
      <Carousel.Item interval={1500}>
        <div
          className="image-fixed d-flex align-items-center justify-content-around"
          style={{
            backgroundImage: `url(${DolunayGuvenlikLogo})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: "whitesmoke",
          }}
        >
          <Container>
            <Row className="justify-content-between">
              <Col xs={6} md={6} lg={4} className="text-center mb-3">
                <Button
                  href="https://onlineislemler.egm.gov.tr/ozelguvenlik/Sayfalar/sinavsonuc.aspx"
                  target="_blank"
                  className="red-main-button"
                >
                  Özel Güvenlik <strong>Temel Eğitim</strong> Sınav Sonucu Öğren
                </Button>
              </Col>
              <Col xs={6} md={6} lg={4} className="text-center mb-3">
                <Button
                  href="https://onlineislemler.egm.gov.tr/ozelguvenlik/Sayfalar/sinavsonuc.aspx"
                  target="_blank"
                  className="red-main-button"
                >
                  Özel Güvenlik <strong>Yenileme Eğitimi</strong> Sınav Sonucu
                  Öğren
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={1500}>
        <Container fluid className="p-0">
          <Row noGutters>
            <Col>
              <Image
                src={DolunayGuvenlikImage3}
                className="image-fixed img-fluid"
                alt="Security Guard"
              />
            </Col>
          </Row>
        </Container>
      </Carousel.Item>
    </Carousel>
  );
}
