import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./componentButton.css";
export default function ExamLinkLayer() {
  const BasicExamLinks = {
    95: "https://egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/95-TEMEL_A-KITAPCIGI.pdf",
    96: "https://egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/96-TEMEL_A-KITAPCIGI.pdf",
    97: "https://egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/97-TEMEL_A-KITAPCIGI.pdf",
    98: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/TEMEL-EGITIM-SINAVI_A-KITAPCIGI.pdf",
    99: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/99-TEMEL-A.pdf",
    100: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/TEMEL100-_A_.pdf",
    101: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/101-Temel-A.pdf",
    102: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/0205-TEMEL-SINAVA-_-A-KITAPCIGI-_1_.pdf",
    103: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/103-Temel-Egitim-A-Kitapcigi.pdf",
    104: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/104_Temel_A.pdf",
    105: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/105-TEMEL-_A_.pdf",
    106: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/106TEMEL_A_CEVAPLI.pdf",
    107: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/107TEMEL_A.PDF",
    108: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/108_TEMEL_A.pdf",
    109: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/Sinav-Kitapcik/109-TEMEL-A-GRUBU-CEVAPLI.pdf",
  };
  const RenewalExamLinks = {
    71: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/71-YENILEME_A-KITAPCIGI.pdf",
    72: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/72-YENILEME_A-KITAPCIGI.pdf",
    73: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/73-YENILEME_A-KITAPCIGI.pdf",
    74: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/YENILEME-EGITIMI-SINAVI_A-KITAPCIGI.pdf",
    75: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/75-YENILEME-A.pdf",
    76: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/YENILEME76-_A_.pdf",
    77: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/77-YENILEME-A.pdf",
    78: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/0205-YENILEME-SINAVI-_-A-KITAPCIGI.pdf",
    79: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/79-Yenileme-Egitimi-A-Kitapcigi.pdf",
    80: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/80_Yenileme_A.pdf",
    81: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/81-YENILEME-_A_.pdf",
    82: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/82YENILEME_A_CEVAPLI.pdf",
    83: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/83YENILEME_A.pdf",
    84: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/84_YENILEME_A.pdf",
    85: "https://www.egm.gov.tr/kurumlar/egm.gov.tr/IcSite/ozelguvenlik/Sinav-Kitapcik/85-YENILEME-A-GRUBU-CEVAPLI.pdf",
  };
  return (
    <Container>
      <Row className="my-5">
        <Col md={6} className="text-center my-3">
          <h5 className="mb-3">
            ÖZEL GÜVENLİK{" "}
            <strong>
              <em>TEMEL EĞİTİM</em>
            </strong>{" "}
            SINAVI SORULARI
          </h5>

          {Object.entries(BasicExamLinks).map(([key, item]) => (
            <Button
              key={key}
              href={item}
              className="custom-button m-1 w-25"
              target="_blank"
            >
              {key}. Dönem
            </Button>
          ))}
        </Col>

        <Col md={6} className="text-center my-3">
          <h5 className="mb-3">
            ÖZEL GÜVENLİK{" "}
            <strong>
              <em>YENİLEME EĞİTİMİ</em>
            </strong>{" "}
            SINAVI SORULARI
          </h5>

          {Object.entries(RenewalExamLinks).map(([key, item]) => (
            <Button
              key={key}
              className="custom-button m-1 w-25"
              href={item}
              target="_blank"
            >
              {key}. Dönem
            </Button>
          ))}
        </Col>
      </Row>
    </Container>
  );
}
