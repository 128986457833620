import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import EducationFirstPage from "./Pages/EducationFirstPage";
import EducationSecondPage from "./Pages/EducationSecondPage";
import EducationThirdPage from "./Pages/EducationThirdPage";
import EducationFourthPage from "./Pages/EducationFourthPage";
import EducationFifthPage from "./Pages/EducationFifthPage";
import Error from "./Error";
import AboutPage from "./Pages/AboutPage";
import MissionPage from "./Pages/MissionPage";
import VisionPage from "./Pages/VisionPage";
import LegislationPage from "./Pages/LegislationPage";
import CommunicationPage from "./Pages/CommunicationPage";
import MyNavbar from "./Components/MyNavbar";
import { ToastContainer, Bounce } from "react-toastify";
function App() {
  return (
    <>
      <MyNavbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/education/1" element={<EducationFirstPage />} />
        <Route path="/education/2" element={<EducationSecondPage />} />
        <Route path="/education/3" element={<EducationThirdPage />} />
        <Route path="/education/4" element={<EducationFourthPage />} />
        <Route path="/education/5" element={<EducationFifthPage />} />
        <Route path="/corporate/about" element={<AboutPage />} />
        <Route path="/corporate/mission" element={<MissionPage />} />
        <Route path="/corporate/vision" element={<VisionPage />} />
        <Route path="/corporate/legislation" element={<LegislationPage />} />
        <Route path="/communication" element={<CommunicationPage />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="colored"
        transition={Bounce}
      />
    </>
  );
}

export default App;
