import React from "react";

export default function GoogleMap() {
  return (
    <div style={{ width: "100%" }}>
      <iframe
        title="Maps"
        width="100%"
        height="400"
        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=37%C2%B052'25.9%22N%2032%C2%B029'08.9%22E+(My%20Business%20Name)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
}
