import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { UserAPI } from "../Services/apis/UserApi";
import SpinnerOverlay from "./SpinnerOverlay.js";
import { toast } from "react-toastify";
import "./componentButton.css";
export default function RegisterFormDialog({ onHide, show }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSetPhoneNumber = (event) => {
    let { value } = event.target;
    // Remove all non-digit characters
    let onlyDigits = value.replace(/\D/g, "");

    if (onlyDigits.startsWith("90")) {
      onlyDigits = "+90" + onlyDigits.substring(2);
    } else if (!onlyDigits.startsWith("+90")) {
      onlyDigits = "+90" + onlyDigits;
    }
    // Remove leading +90 if only +90 is left
    if (onlyDigits === "+90") {
      onlyDigits = "";
    }
    setPhoneNumber(onlyDigits);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    const formData = {
      firstName: form.elements["formControlTextField1"].value,
      lastName: form.elements["formControlTextField2"].value,
      email: form.elements["formControlInput1"].value,
      phone: phoneNumber,
      graduationLevel: form.elements["formControlSelect1"].value,
      birthday: form.elements["formControlDate1"].value,
      educationType: form.elements["formControlSelect2"].value,
      notes: form.elements["formControlTextArea1"].value,
    };

    try {
      setLoading(true);
      const response = await UserAPI.postUser(formData);
      if (response) {
        toast.success("Ön Kaydınız başarıyla gerçekleştirildi.");
        onHide();
      }
    } catch (error) {
      toast.error("Ön Kayıt işlemi sırasında hata oluştu!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg">
        {loading && <SpinnerOverlay />}
        <Modal.Header closeButton>
          <Modal.Title>Ön Kayıt Formu</Modal.Title>
        </Modal.Header>

        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group className="mb-3 row">
              <Form.Group
                controlId="formControlTextField1"
                className="col-sm-6"
              >
                <Form.Label>İsim</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="İsim"
                  autoFocus
                />
                <Form.Control.Feedback type="invalid">
                  Boş Geçilemez!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                controlId="formControlTextField2"
                className="col-sm-6"
              >
                <Form.Label>Soyisim</Form.Label>
                <Form.Control required type="text" placeholder="Soyisim" />
                <Form.Control.Feedback type="invalid">
                  Boş Geçilemez!
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Group>
            <Form.Group className="mb-3 row">
              <Form.Group controlId="formControlInput1" className="col-sm-6">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="name@example.com"
                />
                <Form.Control.Feedback type="invalid">
                  Boş Geçilemez!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formControlInput2" className="col-sm-6">
                <Form.Label>Telefon Numarası</Form.Label>
                <Form.Control
                  required
                  maxLength={13}
                  value={phoneNumber}
                  onChange={handleSetPhoneNumber}
                  type="tel"
                  placeholder={"+90__________"}
                />
                <Form.Control.Feedback type="invalid">
                  Boş Geçilemez!
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Group>
            <Form.Group className="mb-3 row">
              <Form.Group controlId="formControlSelect1" className="col-sm-6">
                <Form.Label>Mezuniyet Düzeyi</Form.Label>
                <Form.Select aria-label="Default select example" required>
                  <option>Mezuniyet Düzeyinizi Seçiniz *</option>
                  <option value="İlkokul">İlkokul</option>
                  <option value="Ortaokul">Ortaokul</option>
                  <option value="Lise">Lise</option>
                  <option value="Üniversite">Üniversite</option>
                  <option value="Üniversite-Yüksek Lisans">
                    Üniversite-Yüksek Lisans
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Boş Geçilemez!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formControlDate1" className="col-sm-6">
                <Form.Label>Doğum Tarihiniz</Form.Label>
                <Form.Control required type="date" />
                <Form.Control.Feedback type="invalid">
                  Boş Geçilemez!
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Group>
            <Form.Group controlId="formControlSelect2" className="mb-3">
              <Form.Label>Eğitim Türü</Form.Label>
              <Form.Select aria-label="Default select example" required>
                <option>Talep Ettiğiniz Eğitim Türünü Seçiniz *</option>
                <option value="Silahlı Özel Güvenlik Eğitimi">
                  Silahlı Özel Güvenlik Eğitimi
                </option>
                <option value="Silahsız Özel Güvenlik Eğitimi">
                  Silahsız Özel Güvenlik Eğitimi
                </option>
                <option value="Silahlı(Yenileme) Özel Güvenlik Eğitimi">
                  Silahlı(Yenileme) Özel Güvenlik Eğitimi
                </option>
                <option value="Silahsız(Yenileme) Özel Güvenlik Eğitimi">
                  Silahsız(Yenileme) Özel Güvenlik Eğitimi
                </option>
                <option value="Silahsızdan Silahlıya Geçiş Eğitimi">
                  Silahsızdan Silahlıya Geçiş Eğitimi
                </option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Boş Geçilemez!
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formControlTextArea1" className="mb-3">
              <Form.Label>Notlar</Form.Label>
              <Form.Control as="textarea" placeholder="Notlar" rows={2} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={onHide}>
              İptal
            </Button>
            <Button className="custom-button" type="submit">
              Kayıt Et
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
