import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import ArrowRepeatIcon from "../assets/icons/ArrowRepeatIcon.svg";
import VCardFillIcon from "../assets/icons/VCardFillIcon.svg";
import VCardIcon from "../assets/icons/VCardIcon.svg";
import Fileearmarkcheck from "../assets/icons/Fileearmarkcheck.svg";
import Fileearmarkcheckfill from "../assets/icons/Fileearmarkcheckfill.svg";

export default function EducationCard({ id, title, description }) {
  let icon;
  if (id === 1) {
    icon = VCardFillIcon;
  } else if (id === 2) {
    icon = VCardIcon;
  } else if (id === 3) {
    icon = Fileearmarkcheckfill;
  } else if (id === 4) {
    icon = Fileearmarkcheck;
  } else if (id === 5) {
    icon = ArrowRepeatIcon;
  } else {
    console.log(icon);
  }

  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
      <Card border="primary" className="h-100" style={{ fontSize: "13px" }}>
        <Row className="justify-content-center">
          <img className="w-25 my-1" src={icon} alt={`ICON!!! ${icon}`} />
        </Row>
        <Card.Body>
          <Row>
            <Card.Title className="text-center">
              <h5>{title}</h5>
            </Card.Title>
          </Row>
          <Row>
            <Card.Text className="text-center">{description}</Card.Text>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}
