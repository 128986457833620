import React from "react";
import MyCarousels from "../Components/MyCarousels";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import FooterLayer from "../Components/FooterLayer";
import Container from "react-bootstrap/Container";
import EducationInfoLayer from "../Components/EducationInfoLayer";
import ExamLinkLayer from "../Components/ExamLinkLayer";
import DolunayGuvenlikImage4 from "../assets/images/DolunayGuvenlikImage4.webp";
import DolunayGuvenlikLogo from "../assets/images/DolunayGuvenlikLogo.webp";
import AltinayGuvenlikLogo from "../assets/images/AltinayGuvenlikLogo.webp";
import ExaminationResultLayer from "../Components/ExaminationResultLayer";
export default function HomePage() {
  const LogoDesign = {
    height: "200px",
  };

  return (
    <>
      <MyCarousels />
      <Container>
        <Row>
          <h3 className="text-center mt-5">
            Neden Konya Dolunay Güvenlik ve Eğitim Kurumu'nun Kursunu Tercih
            Etmelisiniz?
          </h3>
          <Row className="d-flex flex-column flex-md-row">
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center my-3 my-md-5"
            >
              <Image
                src={DolunayGuvenlikImage4}
                alt="DolunayGuvenlikImage"
                className="w-50"
              />
            </Col>
            <Col
              md={6}
              className="d-flex flex-column justify-content-center align-items-center my-3 my-md-0"
            >
              <p className="text-center text-md-left">
                Özel güvenlik eğitimi sektöründe{" "}
                <strong>
                  <em>Konya Dolunay Güvenlik ve Eğitim Kurumu</em>
                </strong>{" "}
                ve{" "}
                <strong>
                  <em>Alparslan Özel Güvenlik Şirketi</em>
                </strong>{" "}
                olarak hizmet vermekteyiz. Firmamız, özel güvenlik sektörünün
                istek ve ihtiyaçları doğrultusunda teknolojik gelişmeleri
                yakından takip ederek özel güvenlik eğitimleri düzenlemekte
                sektörün ihtiyacı olan Özel güvenlik personeli yetiştirmektedir.
              </p>
              <Row className="d-flex justify-content-center my-3 w-100">
                <Col
                  xs={6}
                  md={4}
                  className="d-flex justify-content-center m-1"
                >
                  <Image
                    src={AltinayGuvenlikLogo}
                    alt="AltinayGuvenlikLogo"
                    style={LogoDesign}
                  />
                </Col>
                <Col
                  xs={6}
                  md={4}
                  className="d-flex justify-content-center m-1"
                >
                  <Image
                    src={DolunayGuvenlikLogo}
                    alt="DolunayGuvenlikLogo"
                    style={LogoDesign}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </Container>
      <ExaminationResultLayer />
      <EducationInfoLayer />
      <ExamLinkLayer />
      <FooterLayer />
    </>
  );
}
