import React, { useState } from "react";
import Form from "react-bootstrap/Form";

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import SpinnerOverlay from "./SpinnerOverlay";
import { UserAPI } from "../Services/apis/UserApi";
import { toast } from "react-toastify";
import "./componentButton.css";
export default function CommunicationForm() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSetPhoneNumber = (event) => {
    let { value } = event.target;
    // Remove all non-digit characters
    let onlyDigits = value.replace(/\D/g, "");

    if (onlyDigits.startsWith("90")) {
      onlyDigits = "+90" + onlyDigits.substring(2);
    } else if (!onlyDigits.startsWith("+90")) {
      onlyDigits = "+90" + onlyDigits;
    }
    // Remove leading +90 if only +90 is left
    if (onlyDigits === "+90") {
      onlyDigits = "";
    }
    setPhoneNumber(onlyDigits);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    const formData = {
      firstName: form.elements["formControlTextField1"].value,
      lastName: form.elements["formControlTextField2"].value,
      email: form.elements["formControlInput1"].value,
      phone: phoneNumber,
      subject_title: form.elements["formControlTextField3"].value,
      subject: form.elements["formControlTextArea1"].value,
    };
    try {
      setLoading(true);
      const response = await UserAPI.communation(formData);
      if (response) {
        toast.success("İleti başarıyla kaydedildi.");
        form.reset();
        setPhoneNumber("");
        setValidated(false);
      }
    } catch (error) {
      toast.error("İletim sırasında hata oluştu!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="w-100">
      {loading && <SpinnerOverlay />}
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="p-3"
      >
        <Row className="text-center mb-5">
          <h3>
            <strong>Bizimle</strong> İletişime Geçin
          </h3>
        </Row>
        <Form.Group className="d-flex flex-wrap mb-3 justify-content-between">
          <Form.Group
            controlId="formControlTextField1"
            className="me-2 mb-2 flex-grow-1"
          >
            <Form.Label>İsim</Form.Label>
            <Form.Control required type="text" placeholder="İsim" />
            <Form.Control.Feedback type="invalid">
              Boş Geçilemez!
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            controlId="formControlTextField2"
            className="mb-2 flex-grow-1"
          >
            <Form.Label>Soyisim</Form.Label>
            <Form.Control required type="text" placeholder="Soyisim" />
            <Form.Control.Feedback type="invalid">
              Boş Geçilemez!
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Group>
        <Form.Group className="d-flex flex-wrap mb-3 justify-content-between">
          <Form.Group
            controlId="formControlInput1"
            className="me-2 mb-2 flex-grow-1"
          >
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="name@example.com"
            />
            <Form.Control.Feedback type="invalid">
              Boş Geçilemez!
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            controlId="formControlInput2"
            className="mb-2 flex-grow-1"
          >
            <Form.Label>Telefon Numarası</Form.Label>
            <Form.Control
              required
              maxLength={13}
              value={phoneNumber}
              onChange={(e) => handleSetPhoneNumber(e)}
              type="tel"
              placeholder={"+90__________"}
            />
            <Form.Control.Feedback type="invalid">
              Boş Geçilemez!
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Group>
        <Form.Group controlId="formControlTextField3" className="mb-3">
          <Form.Label>Konu Başlığı</Form.Label>
          <Form.Control required type="text" placeholder="Konu Başlığı" />
          <Form.Control.Feedback type="invalid">
            Boş Geçilemez!
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formControlTextArea1" className="mb-3">
          <Form.Label>Konu</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Konu Metnini Giriniz"
            rows={4}
            required
          />
          <Form.Control.Feedback type="invalid">
            Boş Geçilemez!
          </Form.Control.Feedback>
        </Form.Group>
        <div className="d-flex  justify-content-center">
          <Button className="custom-button" type="submit">
            Gönder
          </Button>
        </div>
      </Form>
    </Container>
  );
}
