import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { ReactComponent as ChevronDoubleDown } from "../assets/icons/ChevronDoubleDown.svg";
import { ReactComponent as PlusLg } from "../assets/icons/PlusLg.svg";
import { toast } from "react-toastify";
import "./components.css";
export default function ScoreCalculator() {
  const [basicScore, setBasicScore] = useState("");
  const [weaponInfoScore, setWeaponInfoScore] = useState("");
  const [hitScore, setHitScore] = useState("");
  const [halfTotal, setHalfTotal] = useState(0);
  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState(<Badge bg="info">Hesaplanmadı</Badge>);

  const handleCalculator = (event) => {
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    try {
      const basicS = parseInt(basicScore);
      const weaponS = parseInt(weaponInfoScore);
      const hitS = parseInt(hitScore);
      if (!isNaN(basicS) && !isNaN(weaponS) && !isNaN(hitS)) {
        let basicweaponPassed = basicS >= 50;
        let basicPassed = basicS >= 60;
        let weaponTotal = weaponS + hitS;

        // Calculate total score check
        let totalScore = basicS + weaponTotal;

        let totalPassed = totalScore / 2 >= 60;
        setHalfTotal(totalScore / 2);
        // Determine exam result based on conditions
        if (basicweaponPassed && totalPassed) {
          setStatus(
            <Badge pill bg="success">
              {"Başarılı".toLocaleUpperCase("tr-TR")}
            </Badge>
          );
        } else if (basicPassed && !totalPassed) {
          setStatus(
            <Badge pill bg="warning" text="dark">
              {"Silahsız Özel Güvenlik sertifikası alabilir".toLocaleUpperCase(
                "tr-TR"
              )}
            </Badge>
          );
        } else {
          setStatus(
            <Badge pill bg="danger">
              {"Başarısız".toLocaleUpperCase("tr-TR")}
            </Badge>
          );
        }
        setBasicScore("");
        setWeaponInfoScore("");
        setHitScore("");
      } else {
        toast.error("Üzgünüz, bir hata oluştu".toLocaleUpperCase("tr-TR"));
      }
    } catch (error) {
      toast.error("Üzgünüz, bir hata oluştu".toLocaleUpperCase("tr-TR"));
    } finally {
      setValidated(false);
    }
  };

  return (
    <Container>
      <Row className="justify-content-center align-items-end">
        <Col md={12} className="mx-1">
          <Form
            noValidate
            validated={validated}
            onSubmit={handleCalculator}
            className="bg-white my-3 p-2 rounded"
          >
            <Form.Group as={Row} className="align-items-center">
              <Col sm={3}>
                <Form.Select
                  aria-label="Default select example"
                  required
                  value={basicScore}
                  onChange={(event) => setBasicScore(event.target.value)}
                >
                  <option value={""}>
                    Temel Sınav Puanı(100 soruluk) Seçiniz!
                  </option>
                  {[...Array(101).keys()].map((value) => (
                    <option key={value} value={`${value}`}>
                      {`${value} puan`}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Hesaplama için boş geçilemez!
                </Form.Control.Feedback>
              </Col>
              <Col
                sm={1}
                className="d-flex justify-content-center align-items-center"
              >
                <PlusLg width={"50px"} fill="black" />
              </Col>

              <Col sm={3}>
                <Form.Select
                  aria-label="Default select example"
                  required
                  value={weaponInfoScore}
                  onChange={(event) => setWeaponInfoScore(event.target.value)}
                >
                  <option value={""}>
                    Silah Bilgisi Puanı(25 soruluk) Seçiniz!
                  </option>
                  {[...Array(26).keys()].map((value) => (
                    <option key={value * 2} value={`${value * 2}`}>
                      {`${value * 2} puan`}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Hesaplama için boş geçilemez!
                </Form.Control.Feedback>
              </Col>
              <Col
                sm={1}
                className="d-flex justify-content-center align-items-center"
              >
                <PlusLg width={"50px"} fill="black" />
              </Col>
              <Col sm={3}>
                <Form.Select
                  aria-label="Default select example"
                  required
                  value={hitScore}
                  onChange={(event) => setHitScore(event.target.value)}
                >
                  <option value={""}>Atış Sayısı(5 atış) Seçiniz!</option>
                  {[1, 2, 3, 4, 5].map((value) => (
                    <option key={value} value={`${value * 10}`}>
                      {`${value} atış -> ${value * 10} puan`}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Hesaplama için boş geçilemez!
                </Form.Control.Feedback>
              </Col>
              <Col
                sm={1}
                className="d-flex justify-content-center align-items-center my-1"
              >
                <Button type="submit">Hesapla</Button>
              </Col>
              <Col
                sm={1}
                className="d-flex justify-content-center align-items-center"
              ></Col>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row className="my-1 justify-content-center">
        <Col xs={4} className="text-center">
          <ChevronDoubleDown className="chevron-icon" />
        </Col>
        <Col xs={4} className="text-center">
          <ChevronDoubleDown className="chevron-icon" />
        </Col>
        <Col xs={4} className="text-center">
          <ChevronDoubleDown className="chevron-icon" />
        </Col>
      </Row>
      <Row className="my-3 justify-content-center">
        <Col xs={12}>
          <Table striped bordered hover responsive>
            <thead>
              <tr className="text-center">
                <th>Sınav Puanı</th>
                <th>Sınav Sonucu</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center">
                <td>{halfTotal}</td>
                <td>{status}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
